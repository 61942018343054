import { Component, OnInit } from '@angular/core';
import { UserblockService } from './userblock.service';
import { AppService } from '../../../core/services/base.service';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    public sender: any;
    user: any;
    pathContextList = "/domain/context/list";
    ctx: any;

    constructor(public userblockService: UserblockService, public appService: AppService, private utilsService  : UtilsService) {
        this.user = {
            name: localStorage.getItem("name"),
            picture: localStorage.getItem("picture") == null ? 'assets/img/logo-single.png' : localStorage.getItem("picture"),
            role: localStorage.getItem("role")
        };
    }

    ngOnInit() {
        this.loadLists()
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

    loadLists(){
        this.appService.getElement(this.pathContextList).subscribe((response) => {
            if( response && response.results ) {
                response.results.some(i => {
                    if (i.code === this.utilsService.getContext()) {
                        this.ctx = i;
                        this.ctx.senders.some(i => {
                            if (i.code == this.utilsService.getSender()) {
                                this.sender = i;
                            }
                        });
                    }
                });
            }
        });
    }        
}
