import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { SenderService, ISender } from '../../../core/services/sender.service';
import { UtilsService , Roles } from '../../../core/services/utils.service';

@Component({
  selector: 'app-s1-select-sender',
  templateUrl: './s1-select-sender.component.html',
  styleUrls: ['./s1-select-sender.component.scss']
})
export class S1SelectSenderComponent implements OnInit {

  @Input() data: Array<ISender>;
  @Input() control: FormControl; //FIXME: fix html quando non viene passato control
  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() readonly: boolean;

  @Output() change = new EventEmitter();

  senderList: Array<ISender>;
  isSuperAdmin: boolean = false;
  userRole: any;

  constructor( private senderService: SenderService, private utils: UtilsService, private translate: TranslateService) { }

  ngOnInit() {
    this.isSuperAdmin = this.utils.isSuperAdmin();
    this.userRole = this.utils.getProfile();
    this.getSenderList();
  }

  getSenderList() {
    if( this.isSuperAdmin || this.userRole == Roles.ContextAdmin ) {
      this.senderService.getSenders().subscribe( resp => {
        console.log( 'getSenderList' , resp )
        if( resp && resp.outcome.success ) {
          this.senderList = resp.data.results;

          this.senderList = this.prependOptionAll( this.senderList );

        }
      })
    }
  }

  onSenderSelected( event ) {
    this.change.emit( event )
  }

  prependOptionAll( array: Array<any> , context?: Object) {
    
    if (array){
      array.some(a=>a.code == '') ? [] : array.unshift(
        { id: null, code: '', description: '', active: null, context: [], expressEnabled: null, senderName: this.translate.instant('senders.lbl_all')} 
      );
    }

    return array;

  }

  public setSenders( newSendersList: ISender[] ) {
    this.senderList = newSendersList;
    this.senderList = this.prependOptionAll( this.senderList );
  }

}
