import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  private path = "/actions/";
  private pathTypes = this.path + "types/";

  constructor(private appService: AppService) { }

  // getTypes(): Observable<any>{
  //   return this.appService.get( this.pathTypes ).pipe(
  //     map( response => {
  //       return response;
  //     })
  //   )
  // }
  async getTypes(): Promise<any>{
    return await (await (await this.appService.get( this.pathTypes ).toPromise()).data).results
  }
}
