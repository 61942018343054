import {Injectable, OnInit} from '@angular/core';
import {ConditionMaster} from "../classes/condition-master";
import {SchemaContainer} from "../classes/schema-container";
import {AppService} from "./base.service";
import {LoggingService} from "./log.service";

@Injectable({
  providedIn: 'root'
})
export class RuleService implements OnInit  {
  public pathEventsSchemas;
  public schemaContainer: SchemaContainer;
  public availableSchemas: Array<any>;
  /** Schema selezionato per la gestione delle regole */
  private selectedJSONSchema: any;

  /** Mappa delle condizioni per */
  public conditionMap = new Map();

  constructor(public appService: AppService,  private logService: LoggingService) {
    this.pathEventsSchemas = '/rules/schemas';
    this.availableSchemas = new Array<any>();
  }

  ngOnInit() {
    // Caricamento delgli schema disponibili
    this.loadSchemas();
  }
  /**
   * Metodo per il caricamento degli schema
   */
  public loadSchemas() {
    this.appService.getElement(this.pathEventsSchemas)
      .subscribe((response) => {
          this.schemaContainer = response;
          this.logService.log("Schema", this.schemaContainer, 300);
          this.schemaContainer.senderSchemaList.forEach(
            element => {
              this.availableSchemas.push(JSON.parse(element));
            }
          );
        },
        (error) => {
          this.logService.log("Error", error, 200);
        }
      );
  }
}
