import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AppService } from "./base.service";
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

import { ISender } from '../services/sender.service';

export interface IContext {
  id: number;
  code: string;
  contextName: string;
  description: string;
  active: boolean;
  expressEnabled: boolean;
  senders: ISender[];
}

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page = 1;
  public numPages = 1;
  public totalItems = 1;

  private path = "/domain/context";
  private pathList = "/domain/context/list";

  constructor( private appService: AppService ) { }

  getContexts(): Observable<any> {
    return this.appService.get( this.pathList ).pipe(
      map( response => {
        return response;
      } )
    );
  }

  createContext( context: IContext ): Observable<any> {
    return this.appService.post( this.path , context ).pipe(
      map( response => {
        return response;
      } )
    );
  }

  updateContext( context: IContext ): Observable<any> {
    return this.appService.put( this.path + '/' + context.id , context ).pipe(
      map( response => {
        return response;
      } )
    );
  }

  deleteContext( id: number ): Observable<any> {
    return this.appService.delete( this.path + '/' + id ).pipe(
      map( response => {
        return response;
      })
    );
  }

}
