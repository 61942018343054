import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppService } from "./base.service";
import { LoggingService } from "./log.service";

@Injectable({
  providedIn: 'root'
})
export class ActivityLogsService {

  private path = "/logs/";
  private pathList = "/logs/list/";
  private pathTimeline = "/logs/timeline/";
  private patthRelatedObject = "/logs/related-object/";

  constructor( private appService: AppService ) { }

  getList( messageId?: number ): Observable<any> {
    return this.appService.get( this.pathList + messageId ).pipe(
      map( response => {
        return response;
      })
    )
  }

  getTimeline( trackId: number ): Observable<any> {
    return this.appService.get( this.pathTimeline + trackId ).pipe(
      map( response => {
        return response;
      })
    )
  }

  getLogDetails( id: number , objectId: number ): Observable<any> {
    return this.appService.get( this.patthRelatedObject + id +'/object/' + objectId ).pipe(
      map( response => {
        return response;
      })
    )
  }

  getLogMessageById( messageId: number ): Observable<any> {
    return this.getList( messageId );
  }

}

export interface ILog {
  contextCode: string,
  contextName: string,
  id: number,
  logError?: any
  logType: string,
  messageId: number
  objectId: number
  objectTs: number
  objectType: string,
  relatedObject?: any
  senderCode: string,
  senderName: string,
  trackId: number
  tsCreated: number
}