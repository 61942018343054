import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { ToastrService } from 'ngx-toastr';

import swal, { SweetAlertResult } from 'sweetalert2';

export enum S1PopUpType {
  Success = "success",
  Warning = "warning",
  Error = "error",
  Info = "info",
  Question = "question"
}

@Injectable({
  providedIn: 'root'
})
export class S1UiService {

  constructor( private translate: TranslateService ) {}

  // Spinner
  showSpinner() {

    swal({
      title: this.translate.instant('s1.swal.loading'),
      onOpen: function () {
        swal.showLoading();
      }
    });

  }

  closeSpinner() {
    swal.close();
  }

  // Popup
  showPopup(type: S1PopUpType, title?: string, text?: string) {

    swal( this.translate.instant(title), this.translate.instant(text)  , type);

  }

  showPopupNoPermission() {
    this.showPopup( S1PopUpType.Warning, 's1.swal.attention',  's1.swal.noPermission' )
  }

  showHTTPErrorPopup(error: any) {
    
    let errorTitle = 's1.error'
    let errorMessage = 'genericError'

    if (error.statusText) {
      errorMessage = error.statusText;
    }

    if (error.status) {

      errorTitle = "" + error.status

      switch(error.status) {
        case 401: // Unauthorized
        case 403: // Forbidden
          errorMessage = this.translate.instant('APIErrors.' + error.status)
          break;
      }

    }

    this.showPopup(S1PopUpType.Error, errorTitle, errorMessage)

  }

  showDialogPopup(title: string): Promise<SweetAlertResult> {

    return swal({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      type: S1PopUpType.Question,
      showCloseButton: true,
      showCancelButton: true, 
      cancelButtonClass: 'btn btn-xs btn-danger'
    })

  }
}
