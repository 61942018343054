import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl , FormBuilder , FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { IContext ,ContextService } from '../../../core/services/context.service';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'app-s1-select-contex',
  templateUrl: './s1-select-contex.component.html',
  styleUrls: ['./s1-select-contex.component.scss']
})
export class S1SelectContexComponent implements OnInit {

  @Input() data: Array<IContext>;
  @Input() control: FormControl; //FIXME: fix html quando non viene passato control
  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() readonly: boolean;

  @Output() change = new EventEmitter();

  // contextList: Array<IContext>;
  contextList: Array<any>;
  isSuperAdmin: boolean = false;
  selectedValue: any;
  defaultControl: FormGroup;

  constructor( private contextService: ContextService, private utils: UtilsService, private translate: TranslateService, private fb: FormBuilder) { }

  ngOnInit() {
    this.defaultControl = this.fb.group({
      context: ['']
    })
    this.isSuperAdmin = this.utils.isSuperAdmin();
    this.getContextList();
  }

  private getContextList() {
    if( this.isSuperAdmin ) {
      this.contextService.getContexts().subscribe( resp => {
        console.log( 'getContextList' , resp )
        if( resp && resp.outcome.success ) {
          this.contextList = resp.data.results;
          this.contextList.unshift({ contextName: this.translate.instant('context.lbl_all'), description: '', code: ''})
        }
      })
    }
  }

  public getContexts() {
    return this.contextList;
  }

  onContextSelected( event ) {
    this.change.emit( event );
  }

}
