import {Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import { FormGroup, FormBuilder } from '@angular/forms';

import { EventFilters } from "../../../core/classes/event-filters";
import { Context } from "../../../core/classes/context";
import { ActionTypes } from "../../../core/classes/action-types";

import { AppService } from "../../../core/services/base.service";
import { LoggingService } from "../../../core/services/log.service";
import { ISender, SenderService } from "../../../core/services/sender.service";
import { IContext, ContextService } from '../../../core/services/context.service';
import { UtilsService , Roles } from '../../../core/services/utils.service';

import { ISchemaContainer } from "../../../core/classes/schema-container";
import { S1SelectContexComponent } from '../../../shared/elements/s1-select-contex/s1-select-contex.component';
import { S1SelectSenderComponent } from '../../../shared/elements/s1-select-sender/s1-select-sender.component';
import { ActivitiesService } from '../../../core/services/activities.service';
import { ExternalFilterService } from '../../../core/services/external-filter.service';
import { MultiselectService } from '../../../core/services/multiselect.service';

interface IFilterCached {
  target: string;
  filter: EventFilters;
}

interface IReadOnlyConfigs {
  context: boolean;
  sender: boolean;
}

@Component({
  selector: 'app-event-filters',
  templateUrl: './event-filters.component.html',
  styleUrls: ['./event-filters.component.scss']
})
export class EventFiltersComponent implements OnInit {

  @ViewChild('context') contextSelectComponent: S1SelectContexComponent;
  @ViewChild('sender') senderSelectComponent: S1SelectSenderComponent;

  @Input('filterType') filterType: number;
  @Input() default: EventFilters;
  @Input() schemasRequired = true;

  @Output('submitFilters') submitFilters: EventEmitter<EventFilters>;

  formManageRule: FormGroup;

  public filterBean: EventFilters;
  public isEventFilter: boolean;
  public isActivityFilter: boolean;
  public isSchemaFilter: boolean;
  public isLogFilter: boolean;
  public isRuleFilter: boolean;
  public isSenderFilter: boolean;
  private filterDefault: any = null;

  bsConfig = {
    containerClass: 'theme-blue'
  };

  // Table filters
  public pathContextList;
  public contextList: Array<Context>;
  public senderList: Array<ISender>;
  public actionTypes: string[];
  public logTypes: string[];
  public objectTypes: string[];
  public statusList: string[];
  public dateStartValue: Date;
  public defaultRuleStatus: number;
  public defaultContext: any;
  public defaultSender: any;
  public userRole: string;
  public roles = Roles;
  public readOnlyConfigs: IReadOnlyConfigs;

  availableSchemas: Array<any> = new Array();

  schemasGroupByFn = (item) => item.contextName || 'ROOT';

  constructor(
    public http: HttpClient,
    public appService: AppService,
    private router: Router,
    private logService: LoggingService,
    private activityService: ActivitiesService,
    public utilsService: UtilsService,
    public fb: FormBuilder,
    private contextService: ContextService,
    private senderService: SenderService,
    public externalFilter: ExternalFilterService,
    private multiselectService: MultiselectService
  ) {

    this.filterBean = new EventFilters();
    this.contextList = new Array<Context>();
    this.senderList = new Array<ISender>();
    this.pathContextList = "/domain/context/list";
    this.statusList =  ActionTypes.EVENT_STATUSES;
    this.dateStartValue  = new Date();
    this.submitFilters = new EventEmitter();
    this.defaultRuleStatus = 0;

    this.readOnlyConfigs = { context: false, sender: false};

    this.formManageRule = this.fb.group({
      ruleName: null,
      description: null,
      ruleStatus: '',
      actionType: '',
      objectType: '',
      selectedContext: '',
      selectedSender: '',
      dateStart: null,
      dateEnd: null,
      status: '',
      logType: '',
      schema: null,
      senderName: null,
      expressEnabled: '',
      senderStatus: '',
      deepSearch: '',
      messageId: null,
      trackId: null,
      idSchema: null,
      email: ''
    });
  }

  async ngOnInit() {
    this.actionTypes = await this.getActionTypes();
    this.logTypes =  await this.getLogTypes();
    this.objectTypes =  await this.getObjectTypes();

    this.isEventFilter = this.filterType === 1;
    this.isActivityFilter = this.filterType === 2;
    this.isLogFilter = this.filterType === 3;
    this.isRuleFilter = this.filterType === 4;
    this.isSenderFilter = this.filterType === 5;
    this.isSchemaFilter = this.filterType === 6;
    if ( this.isRuleFilter ) {
      // this.filterBean.ruleStatus = null;
    }

    // await this.loadContexList();

    // await this.loadSenderList();

    this.userRole = this.utilsService.getProfile();
    this.updateFieldCondition();

    const dataToRestore = this.loadFilterStatus();
    if ( dataToRestore ) {
      console.log('dataToRestore: ', dataToRestore);
      this.updateForm( dataToRestore );

      if ( dataToRestore.contextCode ) {
        this.defaultContext = dataToRestore.contextCode;
        this.formManageRule.controls.selectedContext.patchValue( dataToRestore.contextCode );
        let result: IContext;

        // Recupero i SENDER del CONTEXT selezionato nella precedete ricerca del filtro
        let resp;
        resp = await this.contextService.getContexts().toPromise();

        const _c = resp.data.results.find( x => {
            if ( x.code === this.defaultContext ) {
              result = x;
            }
            return result;
          });

        const _s = _c.senders;

        this.senderList = _s;
      }
      if ( dataToRestore.senderCode ) {
        this.defaultSender = dataToRestore.senderCode;
      }
    }


    if (this.schemasRequired) {
      this.loadSchemas();
    }

    if ( this.default ) {
      Object.keys(this.default).forEach((key) => {
        if ( this.default[key] ) {
          this.filterBean[key] = this.default[key];
          if ( key === 'dateStart' || key === 'dateEnd') {
            this.filterBean[key] = new Date( this.default[key] );
          }
        }
      });
    }

    if (!this.utilsService.isSuperAdmin()) {
      setTimeout(() => {
          this.onChangeContext(null, this.defaultContext);
        }, 260);
    }

    this.filterDefault = this.externalFilter.getFilter();
    this.logService.log("FILTER DEF:", this.filterDefault, 200);
    // inizializzazione filterDefault
    if (this.filterDefault != null) {
      if (this.filterDefault.messageId != null) {
        this.formManageRule.controls.messageId.patchValue(this.filterDefault.messageId);
      }
      if (this.filterDefault.trackId != null) {
        this.formManageRule.controls.trackId.patchValue(this.filterDefault.trackId);
      }
    }

    this.applyFilters();
  }

  /**
   * Caricamento lista dei contesti
   */
  private async loadContexList() {

    if (this.utilsService.isSuperAdmin()) {
      const response = await this.appService.getElement(this.pathContextList).toPromise();
      this.contextList = response.results;
      console.log( 'loadContextList' , this.contextList );
    } else {
      const response = await this.appService.getElement(this.pathContextList).toPromise();
      this.contextList = response.results;
      let ctx;
      response.results.some(i => {
        if (i.code === this.utilsService.getContext()) {
          ctx = i;
          this.defaultContext = i;
          return true;
        }
        return false;
      });
      this.contextList.push(ctx);
    }

  }

  /**
   * Caricamento lista SENDER
   */
  private async loadSenderList() {
    if (this.utilsService.isSuperAdmin() && !this.defaultContext) {
      const response = await this.senderService.getSenders().toPromise();
      if ( response.outcome.success ) {
        this.senderList = response.data.results;
      }
    }
  }

  public onChangeSender(selected: any, selectedContext: any) {
    this.filterBean.senderCode  = selectedContext.code;
  }

  /**
   * Selezione del contesto
   * @param selected
   * @param selectedContext
   */
  public onChangeContext(selected: any, selectedContext: any) {
    console.log( 'onChangeContext' , selectedContext );
    if ( this.utilsService.isSuperAdmin() ) {
      // this.senderSelectComponent.senderList = selectedContext.senders;
      this.senderList = selectedContext.senders;
      this.senderSelectComponent.setSenders( selectedContext.senders );
    } else if ( this.utilsService.isContextAdmin() ) {
      console.log("CONTEXTADMIN", this.defaultContext);
      this.getSenderList();
    } else if ( selectedContext && selectedContext.senders ) {
      let snd;
      selectedContext.senders.some(i => {
        if (i.code === this.utilsService.getSender()) {
          snd = i;
          this.defaultSender = i;
          return true;
        }
        return false;
      });
      this.senderList.push(snd);
    }

    if (selectedContext.code === "" || selectedContext.code !== this.filterBean.contextCode) {
      this.filterBean.senderCode = '';
      this.formManageRule.controls['selectedSender'].patchValue('');
    }
    this.filterBean.contextCode = selectedContext.code;
  }

  public onChangeActionType(selected: any, at: any ) {
    this.filterBean.actionType = at;
  }

  public onChangeStatus(selected: any, st: any ) {
    this.filterBean.status = st;
  }

  public onChangeLogType(selected: any, st: any ) {
    this.filterBean.logType = st;
  }

  public onChangeObjectType(selected: any, st: any ) {
    this.filterBean.objectType = st;
  }

  public onChangeSchema( selected: any, st: any ) {
    this.filterBean.schema = st ? st.schema.$id : null;
  }

  /**
   * @param selected
   * @param startEnd
   */
  public onDateChange( selected: Date, startEnd: string) {
    console.log("dateStart: ", selected);
    console.log("dateStart dt: ", startEnd);
    if ( startEnd === 'start') {
      this.filterBean.dateStart = selected;
    } else if ( startEnd === 'end') {
      this.filterBean.dateEnd = selected;
    }
  }

  /**
   * Metodo per la pulizia dei filtri ed il ripristino dei valori in tabella
   */
  clearFilters(form: FormGroup) {
    form.reset({
      ruleName: '',
      description: '',
      ruleStatus: '',
      actionType: '',
      objectType: '',
      selectedContext: '',
      selectedSender: '',
      dateStart: null,
      dateEnd: null,
      status: '',
      logType: '',
      schema: null,
      senderName: '',
      senderStatus: '',
      expressEnabled: '',
      deepSearch: '',
      messageId: null,
      trackId: null,
      idSchema: null
    });
    this.formManageRule = form;
    this.clearFilterStatus();
    this.externalFilter.clearFilter();
    this.filterBean = new EventFilters();
    this.submitFilters.emit(this.filterBean);
  }

  /**
   * Metodo che attiva il componente padre inviando i filtri
   */
  public applyFilters() {
    console.log('applyFilters - this.filterBean', this.filterBean);
    let message = 'Filters: status: ' + this.filterBean.status;
    message += ' sender: ' + this.filterBean.senderCode;
    message += ' context: ' + this.filterBean.contextCode;
    message += ' description: ' + this.filterBean.description;
    message += ' datestart: ' + this.filterBean.dateStart;
    message += ' dateend: ' + this.filterBean.dateEnd;
    message += ' status: ' + this.filterBean.status;
    message += ' eventEmail: ' + this.filterBean.eventEmail;
    // alert(message);
    this.filterBean.cleared = false;

    if ( this.formManageRule.value.ruleName ) {
      this.filterBean.ruleName = this.formManageRule.value.ruleName;
    }

    if ( this.isSenderFilter ) {
      this.filterBean.senderName = this.formManageRule.value.senderName;
      this.filterBean.description = this.formManageRule.value.description;
      this.filterBean.senderStatus = this.formManageRule.value.senderStatus;
      this.filterBean.expressEnabled = this.formManageRule.value.expressEnabled;
      this.filterBean.deepSearch = this.formManageRule.value.deepSearch;
    }
    if ( this.isEventFilter ) {
      this.filterBean.description = this.formManageRule.value.description;
      this.filterBean.senderName = this.formManageRule.value.senderName;
      this.filterBean.status = this.formManageRule.value.status;
      this.filterBean.eventEmail = this.formManageRule.value.email;
    }
    if ( this.isRuleFilter ) {
      this.filterBean.ruleName = this.formManageRule.value.ruleName;
      this.filterBean.description = this.formManageRule.value.description;
      this.filterBean.ruleStatus = this.formManageRule.value.ruleStatus;
      this.filterBean.senderName = this.formManageRule.value.senderName;
      this.filterBean.actionType = this.formManageRule.value.actionType;
    }
    if ( this.isActivityFilter ) {
      this.filterBean.actionType = this.formManageRule.value.actionType;
      this.filterBean.status = this.formManageRule.value.status;
      this.filterBean.senderName = this.formManageRule.value.senderName;
    }
    if ( this.isSchemaFilter ) {
      this.filterBean.description = this.formManageRule.value.description;
      this.filterBean.idSchema = this.formManageRule.value.idSchema;
    }
    if ( this.isLogFilter ) {
      this.filterBean.objectType = this.formManageRule.value.objectType;
      this.filterBean.status = this.formManageRule.value.status;
      this.filterBean.logType = this.formManageRule.value.logType;
      this.filterBean.messageId = this.formManageRule.value.messageId;
      this.filterBean.trackId = this.formManageRule.value.trackId;
    }

    console.log('applyFilters - this.filterBean (real)', this.filterBean);
    this.saveFilterStatus();
    this.submitFilters.emit( this.filterBean.getFilters() );
  }

  loadSchemas() {
    this.appService.getElement( '/rules/schemas' + "/").subscribe((resp: ISchemaContainer) => {
      this.availableSchemas = this.availableSchemas.concat( resp.rootSchemaList , resp.contextSchemaList , resp.senderSchemaList);
      if ( this.availableSchemas.length > 0 ) {
        this.availableSchemas.map( x => {
          x.schema = JSON.parse(x.schema);
          return x;
        } );
      }
    });
  }

  private saveFilterStatus(): void {
    const dataToSave: IFilterCached = { target: this.router.url , filter: this.filterBean.getFilters() };
    console.log('saveFilterStatus - filterBean.getFilters()' , this.filterBean.getFilters() );
    localStorage.setItem('filterBean' , JSON.stringify(dataToSave) );
  }


  private loadFilterStatus(): EventFilters {
    let dataToRestore: IFilterCached;
    dataToRestore = JSON.parse(localStorage.getItem('filterBean'));
    console.log('dataToRestore' , dataToRestore );
    if ( dataToRestore ) {
      if ( this.router.url === dataToRestore.target ) {
        return dataToRestore.filter;
      } else {
        return new EventFilters();
      }
    }
  }

  private clearFilterStatus(): void {
    localStorage.removeItem('filterBean');
  }

  private updateForm( data ): void {
    Object.keys(this.formManageRule.controls).forEach((key) => {
      console.log( 'Object.keys - key' , key  , 'data[key]' , data[key] );

      if ( (key === 'selectedContext' || key === 'selectedSender' || key === 'status'
      || key === 'deepSearch' || 'updateForm') && !data[key] ) {
        this.formManageRule.controls[key].patchValue( '' );
      } else {

        if ( key === 'dateStart' || key === 'dateEnd') {
          this.formManageRule.controls[key].patchValue( new Date(data[key]) );
        } else {
          this.formManageRule.controls[key].patchValue( data[key] );
        }

      }
    });
  }

  private updateFieldCondition() {
    if ( this.userRole === Roles.SuperAdmin ) {

    } else if ( this.userRole === Roles.ContextAdmin ) {
      this.readOnlyConfigs.context = true;
    } else if ( this.userRole === Roles.SenderAdmin) {
      this.readOnlyConfigs.context = true;
      this.readOnlyConfigs.sender = true;
    }
  }

  /**
   * Caricamento lista SENDER
   */
  private async getSenderList() {

    const response = await this.senderService.getSenders().toPromise();
    if ( response.outcome.success ) {
      this.senderList = response.data.results;
    }

  }

  async getActionTypes() {
    return await this.activityService.getTypes();
  }

  async getLogTypes(): Promise<string[]> {
    return this.multiselectService.getLogTypes();
  }

  getLogTypesFiltred(objTypeSelected: string): string[] {
    // la seguente funzione restituisce i logTypes filtrati sulla base del object type selezionato
    const res: string[] = [];

    if (objTypeSelected === '') {
      return this.logTypes;
    }

    this.logTypes.forEach( type => {
      // verifico che il codice (stringa) in "tipo entità" (object types) sia nella stringa dei codici di "tipo LOG" (log types)
      if (type.includes(objTypeSelected)) {
        const bothContainsExpress: boolean = type.includes('EXPRESS') && objTypeSelected.includes('EXPRESS');
        const noneContainsExpress: boolean = !type.includes('EXPRESS') && !objTypeSelected.includes('EXPRESS');
        // se contiene express devono contenerlo entrambi altrimenti nessuno dei due
        if (bothContainsExpress || noneContainsExpress) {
          res.push(type);
        }
      }
    });

    return res;
  }

  async getObjectTypes(): Promise<string[]> {
    return this.multiselectService.getObjectTypes();
  }
}
