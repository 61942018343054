// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // restBaseUrl: 'http://192.168.2.55:8080/product-rest/rest',
  // restBaseUrl: 'http://localhost:8080/saiot-rest/rest',
  // restBaseUrl: 'https://api.saiot.it/saiot-rest/rest',
  restBaseUrl: 'https://testapi.saiot.it/saiot-rest/rest',
  // restBaseUrl: 'http://185.56.9.206/saiot-rest/rest',
  // restBaseUrl: 'http://localhost:8080/saiot-rest/rest', // Server Locale - ECLIPSE TOMCAT
  // restBaseUrl: 'http://192.168.2.58:8080/saiot-rest/rest', // PC Stefano V.
  rows: 20,
  version: '1.3.1'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
