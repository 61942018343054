import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ExternalFilterService {
    private extFilter: any;

    getFilter(): any {
        return this.extFilter;
    }

    setFilter(filter: any) {
        this.extFilter = filter;
    }

    clearFilter() {
        this.extFilter = null;
    }
}
