import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatorService } from '../../core/translator/translator.service';
const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';

export interface NavItem {
  name: String
  link: String
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  selectedLanguage = "";

  isNavSearchVisible: boolean;
  @ViewChild('fsbutton') fsbutton;  // the fullscreen button
  profile: any;
  navItems = new Array<NavItem>();

  constructor(public menu: MenuService, public userblockService: UserblockService, public settings: SettingsService, private router: Router, public translator: TranslatorService) {

    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
    this.selectedLanguage = this.getLangs()[0].code;
  }

  ngOnInit() {
    this.isNavSearchVisible = false;
    if (browser.msie) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }
  }

  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(value) {
    this.translator.useLanguage(value);
    this.selectedLanguage = value;
  }


  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  logout() {
    this.settings.sessionExpired();
    this.router.navigate(["/login/0"]);
  }
    
}
