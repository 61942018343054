import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

import { MenuService } from '../../core/menu/menu.service';
import { SettingsService } from '../../core/settings/settings.service';

import { menu } from '../../routes/menu';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

    menuItems: Array<any>;
    router: Router;
    sbclickEvent = 'click.sidebar-toggle';
    $doc: any = null;
    profile: any;

    constructor(public menuService: MenuService, public settings: SettingsService, public injector: Injector ) {
        this.menuItems = menuService.getMenu();
    }

    ngOnInit() {
        this.profile = localStorage.getItem('role');
        console.log( 'this.profile' , this.profile );
        this.initMenu();
        this.router = this.injector.get(Router);
        this.router.events.subscribe((val) => {
            // close any submenu opened when route changes
            this.removeFloatingNav();
            // scroll view to top
            window.scrollTo(0, 0);
            // close sidebar on route change
            this.settings.setLayoutSetting('asideToggled', false);
        });
        // enable sidebar autoclose from extenal clicks
        this.anyClickClose();
    }

    anyClickClose() {
        this.$doc = $(document).on(this.sbclickEvent, (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.settings.setLayoutSetting('asideToggled', false);
            }
        });
    }

    ngOnDestroy() {
        if (this.$doc) {
            this.$doc.off(this.sbclickEvent);
        }
    }

    toggleSubmenuClick(event) {

        event.preventDefault();

        if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {

            const target = $(event.target || event.srcElement || event.currentTarget);
            let ul, anchor = target;

            // find the UL
            if (!target.is('a')) {
                anchor = target.parent('a').first();
            }
            ul = anchor.next();

            // hide other submenus
            const parentNav = ul.parents('.sidebar-subnav');
            $('.sidebar-subnav').each((idx, el) => {
                const $el = $(el);
                // if schemaContainer is not a parent or self ul
                if (!$el.is(parentNav) && !$el.is(ul)) {
                    this.closeMenu($el);
                }
            });

            // abort if not UL to process
            if (!ul.length) {
                return;
            }

            // any child menu should start closed
            ul.find('.sidebar-subnav').each((idx, el) => {
                this.closeMenu($(el));
            });

            // toggle UL height
            if (parseInt(ul.height(), 0)) {
                this.closeMenu(ul);
            } else {
                // expand menu
                ul.on('transitionend', () => {
                    ul.height('auto').off('transitionend');
                }).height(ul[0].scrollHeight);
                // add class to manage animation
                ul.addClass('opening');
            }

        }

    }

    // Close menu collapsing height
    closeMenu(elem) {
        elem.height(elem[0].scrollHeight); // set height
        elem.height(0); // and move to zero to collapse
        elem.removeClass('opening');
    }

    toggleSubmenuHover(event) {
        const self = this;
        if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
            event.preventDefault();

            this.removeFloatingNav();

            const target = $(event.target || event.srcElement || event.currentTarget);
            let ul, anchor = target;
            // find the UL
            if (!target.is('a')) {
                anchor = target.parent('a');
            }
            ul = anchor.next();

            if (!ul.length) {
                return; // if not submenu return
            }

            const $aside = $('.aside-container');
            const $asideInner = $aside.children('.aside-inner'); // for top offset calculation
            const $sidebar = $asideInner.children('.sidebar');
            const mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
            const itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();

            const floatingNav = ul.clone().appendTo($aside);
            const vwHeight = $(window).height();

            // let itemTop = anchor.position().top || anchor.offset().top;

            floatingNav
                .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
                .addClass('nav-floating')
                .css({
                    position: this.settings.getLayoutSetting('isFixed') ? 'fixed' : 'absolute',
                    top: itemTop,
                    bottom: (floatingNav.outerHeight(true) + itemTop > vwHeight) ? 0 : 'auto'
                });

            floatingNav
                .on('mouseleave', () => { floatingNav.remove(); })
                .find('a').on('click', function(e) {
                    e.preventDefault(); // prevents page reload on click
                    // get the exact route path to navigate
                    const routeTo = $(this).attr('route');
                    if (routeTo) { self.router.navigate([routeTo]); }
                });

            this.listenForExternalClicks();

        }

    }

    listenForExternalClicks() {
        const $doc = $(document).on('click.sidebar', (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.removeFloatingNav();
                $doc.off('click.sidebar');
            }
        });
    }

    removeFloatingNav() {
        $('.nav-floating').remove();
    }

    isSidebarCollapsed() {
        return this.settings.getLayoutSetting('isCollapsed');
    }
    isSidebarCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }
    isEnabledHover() {
        return this.settings.getLayoutSetting('asideHover');
    }

    initMenu() {
      console.log('InitMenu()');
      switch (this.profile) {
      case 'CONTEXT_ADMIN':
        console.log( 'initMenu()' , 'sono CONTEXT_ADMIN' );
        this.menuItems = [
          {
            text: 'Home',
            translate: 'menu.home',
            link: '/home',
            icon: 'icon-home'
          },
          {
            text: 'User',
            translate: 'menu.users',
            link: '/user',
            icon: 'icon-user'
          },
          {
            text: 'Senders',
            translate: 'menu.senders',
            link: '/senders',
            icon: 'icon-people'
          },
          {
            text: 'Events',
            translate: 'menu.events',
            link: '/events',
            icon: 'icon-feed'
          },
          {
            text: 'Rules',
            translate: 'menu.rules',
            link: '/rules',
            icon: 'icon-chemistry'
          },
          {
            text: 'Activities',
            translate: 'menu.activities',
            link: '/activities',
            icon: 'fab fa-whmcs'
          },
          {
            text: 'Schemas',
            translate: 'menu.schemas',
            link: '/schemas',
            icon: 'fas fa-code'
          },
          {
            text: 'Logs',
            translate: 'menu.logs',
            link: '/logs',
            icon: 'fas fa-book'
          }
        ];
        break;
      case 'SENDER_ADMIN':
        console.log( 'initMenu()' , 'sono SENDER_ADMIN' );
        this.menuItems = [
          {
            text: 'Home',
            translate: 'menu.home',
            link: '/home',
            icon: 'icon-home'
          },
          {
            text: 'Events',
            translate: 'menu.events',
            link: '/events',
            icon: 'icon-feed'
          },
          {
            text: 'Rules',
            translate: 'menu.rules',
            link: '/rules',
            icon: 'icon-chemistry'
          },
          {
            text: 'Activities',
            translate: 'menu.activities',
            link: '/activities',
            icon: 'fab fa-whmcs'
          },
          {
            text: 'Schemas',
            translate: 'menu.schemas',
            link: '/schemas',
            icon: 'fas fa-code'
          },
          {
            text: 'Logs',
            translate: 'menu.logs',
            link: '/logs',
            icon: 'fas fa-book'
          }
        ];
        break;
      }
      console.log( 'this.menuItems = ' , this.menuItems );
      console.log( 'cont menuService = ' , this.menuService );
    }
}
