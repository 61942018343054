export class EventFilters {
  cleared: boolean;
  type: number;
  contextCode: string;
  senderCode: string;
  dateStart: Date;
  dateEnd: Date;
  description: string;
  actionType: string;
  status: string;
  logType: string;
  objectType: string;
  /** Campi filtro specifici per elenco regole */
  ruleName: string;
  ruleStatus: number;
  schema: string;
  senderName: string;
  expressEnabled: number;
  senderStatus: number;
  deepSearch: number;

  messageId: number;
  trackId: number;
  idSchema: number;
  /** Campi per il passaggio dei tms delle date di filtro */
  tmsStart: number;
  tmsEnd: number;

  eventEmail: string;

  constructor() {
    this.cleared = true;
  }
  public getFilters(): EventFilters  {
    console.log("GETFILTERS", this.dateStart, this.dateEnd);
    if (this.dateStart) {
      this.tmsStart = this.dateStart.getTime();
    } else {
      this.tmsStart = null;
    }
    if (this.dateEnd) {
      this.tmsEnd = this.dateEnd.getTime();
    } else {
      this.tmsEnd = null;
    }
    return this;
  }
}
