import { Injectable } from '@angular/core';
import { AppService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class MultiselectService {

  private path = "/multiselect/";
  private objectTypes = this.path + "object_types/";
  private logTypes = this.path + "log_types/";

  constructor(private appService: AppService) { }

  async getObjectTypes(): Promise<string[]>{
    return await (await (await this.appService.get( this.objectTypes ).toPromise()).data).results.map(x=>x.name)
  }

  async getLogTypes(): Promise<string[]>{
    return await (await (await this.appService.get( this.logTypes ).toPromise()).data).results.map(x=>x.name)
  }

}