import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormControlName, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export interface IS1InputSelectItem {
  code: string | number;
  label: string;
}

@Component({
  selector: 'app-s1-select-three-state-option',
  templateUrl: './s1-select-three-state-option.component.html',
  styleUrls: ['./s1-select-three-state-option.component.scss']
})
export class S1SelectThreeStateOptionComponent {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() readonly: boolean = false;
  @Input() selectId: string = '';

  itemsList: IS1InputSelectItem[];

  constructor() {
    const traslatePrefix = 'base.';
    this.itemsList = [
      {
        code: '',
        label: traslatePrefix + 'lbl_all'
      },
      {
        code: '1',
        label: traslatePrefix + 'lbl_active'
      },
      {
        code: '0',
        label: traslatePrefix + 'lbl_disactive'
      }
    ];
  }
  
}
