import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment'
declare var $: any;

@Injectable()
export class SettingsService {

    private user: any;
    private app: any;
    private layout: any;
    private errorTranslation: any;

    constructor(private translate: TranslateService, private router: Router) {
        this.errorTranslation = this.translate.get('error');
        // User Settings
        // -----------------------------------
        this.user = {
            name: '',
            job: '',
            picture: 'assets/img/user/02.jpg'
        };

        // App Settings - footer
        // -----------------------------------
        this.app = {
            name: 'Soluzione 1',
            description: 'Archetipo SAIOT Frontend',
            year: ((new Date()).getFullYear()),
            version: environment.version
        };

        // Layout Settings - basic settings for layout & Co.
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

    }

    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    sessionExpired() {
      localStorage.clear();
    }

    manageErrorMsg(outcome) {
      this.errorTranslation = this.translate.get('error');
      let message = "";
      switch (outcome.code) {
        case '0001':
          message = this.errorTranslation.value.error_0001;
        break;
        case '0002':
          message = this.errorTranslation.value.error_0002;
        break;
        case '0003':
          message = this.errorTranslation.value.bad_credential;
        break;
        case '0004':
          message = this.errorTranslation.value.wrong_password;
        break;
        case '0005':
          this.sessionExpired();
          this.router.navigate(["/login/0"]);
        break;
        case '0006':
          message = this.errorTranslation.value.account_disabled;
        break;
        case '0007':
          this.sessionExpired();
          this.router.navigate(["/login/0"]);
          message = this.errorTranslation.value.auth_required;
        break;
        default:
          message = this.errorTranslation.value.generic_error;
        break;
      }
      return message;
    }

}
