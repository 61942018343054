import { Component, OnInit } from '@angular/core';

import { SettingsService } from '../../core/settings/settings.service';
import { AppService } from '../../core/services/base.service';

@Component({
	selector: '[app-footer]',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	versionBE;

	constructor(public settings: SettingsService , private appService: AppService) {
		this.appService.getVersionBE().subscribe((resp) => {
			if (resp && resp.outcome.success) {
				this.versionBE = resp.data;
			}
		});
	}

	ngOnInit() {

	}

}
