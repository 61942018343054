import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {

  @Input() toggleId: number;
  @Input() enabled: boolean;
  @Input() useIcons?: boolean;
  @Input() offIcon?: string;
  @Input() onIcon?: string;

  @Output() changed = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }
}
