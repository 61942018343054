import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LoggingService } from './log.service';
import { throwError } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { S1UiService } from './s1-ui.service';

@Injectable()
export class AppService {

  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page = 1;
  public numPages = 1;
  public totalItems = 1;

  constructor(private http: HttpClient, private logger: LoggingService, private router: Router, private settings: SettingsService , private ui: S1UiService) {}

  // Rest Items List Service
  getList(path, filters) {
    // stringa per chiamata da fare
    let queryStr = "";
    let i = 0;
    let stringAmp = "";
    // imposto i parametri del filtro
    /**
     * Probabilmente non utilizzato
     */
    if (filters !== null) {
      Object.keys(filters).forEach(function (key) {
        if (filters[key] != null &&   filters[key].toString() !== "") {
          if (i > 0) { stringAmp = "&"; }
          queryStr = queryStr + stringAmp + key + "=" + filters[key];
          i++;
        }
      });
    }

    // creo tutta la chiamata completa di paginazione e righe
    queryStr = environment.restBaseUrl
    + path + "?" + queryStr
    + "&paging=" + this.paging
    + "&page=" + this.page
    + "&rows=" + environment.rows;


    // clean url
    queryStr = queryStr.replace("?&", "?");

    this.logger.log("Chiamata URL lista:" + queryStr, "", 200);

    return this.http
      .get<any[]>(queryStr, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const data = response['data'];
        // this.totalItems = data['total'];

        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return data;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // Rest Items Service: Read one schemaContainer (detail)
  getElement(path) {
    path = environment.restBaseUrl + path;
    return this.http
      .get<any[]>(path, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        // this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // Rest Items Service: Read all REST Items
  getAll(path) {
    path = environment.restBaseUrl + path;
    return this.http
      .get<any[]>(path, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        // this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // get schemaContainer list by data passed
  getAllFromData(path, requestData): any {
    path = environment.restBaseUrl + path;
    return this.http
      .post<any[]>(path, requestData, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        const res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // creation of new schemaContainer
  newElement(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .post<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        const res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // modify of an schemaContainer
  editElement(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        const res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // modify of an schemaContainer
  deleteElement(path ): any {
    path = environment.restBaseUrl + path;
    return this.http
      .delete<any[]>(path , this.getRequestOptionArgs()).pipe(map(
        (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return response;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        const res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  invite(idUser: number): any {
    const path = environment.restBaseUrl + '/admin/user/invite/' + idUser;
    const elementRequest: any = {};

    // TODO: inserire la chiamata al servizio corretto!
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        const res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

    // upload file
    uploadElement(path, elementRequest: File|FormData): any {
      path = environment.restBaseUrl + path;

      return this.http
        .post<any[]>(path, elementRequest, this.getRequestOptionArgsUpload())
        .pipe(map(
        (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return response;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
          this.logger.log("Error Response:", errorResponse, 200);
          const res: any = errorResponse.error;
          if (res.outcome.code === "0005" || res.outcome.code === "0007") {
            this.settings.sessionExpired();
          }
          return throwError(errorResponse.error);
        }
      ));
    }

  getRequestOptionArgs(): any {
    console.log("getRequestOptions");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
      })
    };
    return httpOptions;
  }

  getRequestOptionArgsUpload(): any {
    console.log("getRequestOptionsUpload");
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/x-www-form-urlencoded',
        // 'Content-Type': 'multipart/form-data',
        'X-Auth-Token': localStorage.getItem("token") != null ? localStorage.getItem("token") : '',
        'Accept': ''
      })
    };
    return httpOptions;
  }

  get(path: string, urlParameters: any = {}, showUI: boolean = true) { // : Observable<IS1Response>
    const parameters = this.mapParameters(urlParameters);

    this.logger.log("GET: ", path, 200);
    this.logger.log("Par: ", parameters, 200);

    // if (showUI) {
    //   this.ui.showSpinner()
    // }

    return this.http.get<any>(environment.restBaseUrl + path + parameters, this.getRequestOptionArgs())
      .pipe(
        map(( response: IS1Response ) => {
          return this.handleResponse(response, showUI);
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error, showUI);
        })
      );

  }

  /**
   * Wrapper of `HTTPClient` post function that centralize the managment of base url, errors and UI feedback
   *
   * @param path relative path of the API call.
   * @param body JSON body of the API call.
   * @param showUI determine to show or not UI feedback
   * @returns `Observable` of type `IS1Response`
   */

  post(path: string, body: any, showUI: boolean = true) { // : Observable<IS1Response>

    this.logger.log("POST: ", path, 200);
    this.logger.log("Par: ", body, 200);

    if (showUI) {
      this.ui.showSpinner();
    }

    return this.http.post<IS1Response>(environment.restBaseUrl + path, body, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      );
  }

  put(path: string, body: any, showUI: boolean = true) { // : Observable<IS1Response>

    this.logger.log("PUT: ", path, 200);
    this.logger.log("Par: ", body, 200);

    if (showUI) {
      this.ui.showSpinner();
    }

    return this.http.put<IS1Response>(environment.restBaseUrl + path, body, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      );
  }

  delete(path: string, showUI: boolean = true) { // : Observable<IS1Response>

    this.logger.log("DELETE: ", path, 200);

    if (showUI) {
      this.ui.showSpinner();
    }

    return this.http.delete<any>(environment.restBaseUrl + path, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      );

  }

  getVersionBE() {
    return this.get('/general/version').pipe(
      map( response => {
        return response;
      } )
    );
  }

  private mapParameters(parametersObj: any): string {

    let parameterString = "?";

    // tslint:disable-next-line: forin
    for (const key in parametersObj) {
      if (parameterString !== "?") {
        parameterString += "&";
      }

      const value = parametersObj[key];

      if (value != null) {
        parameterString += key + "=" + encodeURIComponent(value);
      }
    }

    return parameterString;

  }

  private handleResponse( response: IS1Response, showUI: boolean = true) {

    const s1Response = response;

    this.logger.log("Response: ", s1Response, 200);

    if (s1Response.outcome && s1Response.outcome.success) {

      if (showUI) {
        this.ui.closeSpinner();
      }
      return s1Response;

    } else {

      let status = s1Response.outcome.code || '499';
      status = (status === '0005') ? '403' : status;

      throw new HttpErrorResponse({
        status: +status,
        statusText: s1Response.outcome.errorMessage || s1Response.outcome.message,
        headers: s1Response.headers,
        url: s1Response.url
      });

    }

  }

  private handleError(error: HttpErrorResponse, showUI: boolean = true) {

    this.logger.log("HTTP Error: ", error, 200);

    if (showUI) {
      this.ui.showHTTPErrorPopup(error);
    }

    switch (error.status) {
      case 401: // Unauthorized
      case 403: // Forbidden
        localStorage.clear();
        this.router.navigate(["/login/1"]);
        break;
    }

    return throwError(error);

  }

}

@Injectable()
export class PaginationService {

  public paging: boolean;
  public itemsPerPage: number; // server pagination
  public page = 1;
  public numPages: number;
  public totalItems: number;

  constructor() {
    this.paging = true;
    this.itemsPerPage = environment.rows;
    this.page = 1;
    this.numPages = 1;
    this.totalItems = 1;
  }

}

interface IS1Response extends HttpResponse<any> {
  outcome: IS1Outcome;
  item?: any;
  results?: any[];
  data?: any;
  paginationInfo?: IS1PaginationInfo;
  total?: number;
}

interface IS1Outcome {
  success: boolean;
  errorMessage: string;
  message: string;
  code?: string;
}

export interface IS1PaginationInfo {
  actualPage: number;
  next: boolean;
  pageSize: number;
  previous: boolean;
  totalItems: number;
  totalPages: number;
}
