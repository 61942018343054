
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import {NgModule} from '@angular/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from '../../app.component';

import { CoreModule } from '../../core/core.module';
import { LayoutModule } from '../../layout/layout.module';
import { LayoutBlankModule } from '../../layout_blank/layout_blank.module';
import { SharedModule } from '../../shared/shared.module';
import { RoutesModule } from '../routes.module';

import { AppService , PaginationService } from '../../core/services/base.service';
import { LoggingService } from '../../core/services/log.service';
import { AuthService } from '../../core/services/auth.service';
import {RuleService} from "../../core/services/rule.service";
import {AuthInterceptor} from "../../core/services/auth-interceptor";

import { Action } from 'rxjs/internal/scheduler/Action';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        
    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        LayoutModule,
        LayoutBlankModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    exports: [],
    providers: [AppService, LoggingService, AuthService,  RuleService, PaginationService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      }
      ],
    bootstrap: [AppComponent]
})
export class AppModule { }
