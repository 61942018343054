import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appEnabledDirective]'
})
export class EnabledDirectiveDirective implements OnInit {

  @Input() enabled: boolean;
  @Input() bordered  = false;
  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    if (!this.bordered) {
       this.el.nativeElement.style.color = this.enabled ? 'green' : 'red';
      // this.el.nativeElement.class = this.enabled ? 'ruled-enabled' : 'ruled-disabled';
    } else {
      this.el.nativeElement.style.borderColor = this.enabled ? 'green' : 'red';
      this.el.nativeElement.style.borderWidth = '2px';
    }
  }

}
