import {Injectable, EventEmitter, Output} from '@angular/core';
import {AppService} from "./base.service";
import {LoggingService} from "./log.service";
import { TranslateService } from '@ngx-translate/core';
import { locateHostElement, text } from '@angular/core/src/render3/instructions';

export enum Roles {
  SuperAdmin = 'SUPERADMIN',
  Admin = 'ADMIN',
  ContextAdmin = 'CONTEXT_ADMIN',
  SenderAdmin = 'SENDER_ADMIN'
}
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  @Output() serviceDoneEmit = new EventEmitter<any>();
  
  private _str: string= "";

  constructor(
    private translate: TranslateService,
    public appService: AppService, 
    private logService: LoggingService
  ) { }

  serviceDone( ref = null ) {
    this.logService.log( 'emit' , ref , 200)
    this.serviceDoneEmit.emit( ref )
  }

  isSuperAdmin() {
    if(localStorage.getItem('role') == 'SUPERADMIN')
    return true
    else if(localStorage.getItem('role') == 'ADMIN')
    return true
    else 
    return false
  }

  isContextAdmin(): boolean {
    return localStorage.getItem('role') == 'CONTEXT_ADMIN'
  }

  isSenderAdmin(): boolean {
    return localStorage.getItem('role') == 'SENDER_ADMIN'
  }

  getContext(): string {
    return localStorage.getItem('context')
  }

  getSender(): string {
    return localStorage.getItem('sender')
  }

  getProfile(): string {
    return localStorage.getItem('role')
  }

  getTargetFieldList() {
    return [ {value: "RETURN_DATA"} , {value: "USE_DATA_SCHEMA"} ]
  }

  isTypeObject( val ) {
    if(typeof val === 'object' && val !== null) {
      return true;
    } else {
      return false;
    }
  }

  jsonParser( val ) {
    if( this.isTypeObject( val) ) {
      this._str += '<ul>';

      Object.keys(val).forEach((key) => {
        this._str += '<li>';
        if( this.isTypeObject( val[key] ) ) {
          this._str += '<span class="text-muted">' + key + ': </span>';
          this.jsonParser( val[key] )
        } else {
          this._str += '<span class="text-muted">' + key + ': </span>' + '<span><b>' + val[key] + '</b></span>';
        }
        this._str += '</li>';
      })

      this._str += '</ul>';
    }
  }

  toJson( val ) {
    this._str = "";
    this.jsonParser( val );
    return this._str;
  }

  stringToFilterbean( string: any ) {
    console.log( 'inside function ' , string )
    string = string.split("=")
    console.log( 'string' , string )
    string[1] = string[1].replace(/'/g, "")
    console.log( 'string + +' , string )
    return string;
  }

  translateElement(location: string){  
    let text: string
    this.translate.get('base.'+location).subscribe((txt: string) => {
      text= txt;
    });
    return text;
  }

  formatDateFromString( dateTime: string , returnString: boolean = true ) {
    let dt = new Date( dateTime );
    console.log( 'formatDateFromString - dt' , dt )
    return dt;
  }
    
}
