import { Component, Input , OnChanges, SimpleChanges, Output , EventEmitter } from "@angular/core";

import { UtilsService } from '../../../core/services/utils.service';

@Component({
	selector: 'sol-dashboardtile', 
	templateUrl: './sol-dashboardtile.component.html',
	styleUrls: ['./sol-dashboardtile.component.scss']
})

export class SolDashboardTileComponent {

	@Input('data') items: any;
	@Input() innerBoxes: number;
	@Input('tileTitle') title: string; 
	@Input() translationPrefix: string;
	@Input() stackDirection: string;
	@Input() type: string;
	@Output() click = new EventEmitter();

	constructor( 
		private utils: UtilsService 
	) {
		if( !this.stackDirection ) {
			this.stackDirection = 'vertical'
		}
		// this.items = new Array();
		console.log( 'inside component - this.items' , this.items )
	}

	goToFilteredPage( queryParams: any , sezione: string) {
		this.click.emit( {queryParams , sezione } );
	}

}