import { LayoutComponent } from '../layout/layout.component';
import { LayoutBlankComponent } from '../layout_blank/layout_blank.component';

export const routes = [

    {
      path: '',
      component: LayoutComponent,
      children: [
          { path: '', redirectTo: 'home', pathMatch: 'full' },
          { path: 'home', loadChildren: './home/home.module#HomeModule' },
          { path: 'context', loadChildren: './context/context.module#ContextModule' },
          { path: 'user', loadChildren: './users/users.module#UsersModule' },
          { path: 'senders', loadChildren: './senders/senders.module#SendersModule'},
          { path: 'events', loadChildren: './events/events.module#EventsModule' },
          { path: 'rules', loadChildren: './rules/rule.module#RuleModule'},
          { path: 'activities', loadChildren: './activities/activity.module#ActivityModule'},
          { path: 'logs', loadChildren: './logs/logs.module#LogsModule'},
          { path: 'elements', loadChildren: './elements/elements.module#ElementsModule'},
          { path: 'schemas', loadChildren: './actions-schemas/actions-schemas.module#ActionsSchemasModule'},
      ]
    },

    {
      path: '',
      component: LayoutBlankComponent,
      children: [
          { path: 'login', loadChildren: './pages/login/login.module#LoginModule' },
          { path: 'login/:id', loadChildren: './pages/login/login.module#LoginModule' },
          { path: 'register', loadChildren: './pages/register/register.module#RegisterModule' },
          { path: 'recover', loadChildren: './pages/recover/recover.module#RecoverModule' },
          { path: 'activate/:id', loadChildren: './pages/activate/activate.module#ActivateModule' },
          { path: 'lock', loadChildren: './pages/lock/lock.module#LockModule' },
          { path: '404', loadChildren: './pages/error404/error404.module#Error404Module' },
          { path: '500', loadChildren: './pages/error500/error500.module#Error500Module' },
      ]
    },

    // Not lazy-loaded routes
    /*{ path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },*/

    // Not found
    { path: '**', redirectTo: '404' }

];
