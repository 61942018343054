export class ActionTypes {
  static readonly EVENT_TYPE_EMAIL = 'EMAIL';
  static readonly EVENT_TYPE_EMAIL_TEMPLATE = 'EMAIL_TEMPLATE';
  static readonly EVENT_TYPE_SMS = 'SMS';
  static readonly EVENT_TYPE_WEBHOOK = 'WEBHOOK';
  static readonly EVENT_TYPE_SMS_WA = 'SMS_WA';

  static readonly EVENT_STATUSES = ['NEW', 'PROCESSED', 'PROCESSED_EXPRESS', 'ERROR'];
  static readonly ACTION_TYPES = ['EMAIL', 'SMS'];
  static readonly OBJECT_TYPES = [
    'EVENT',
    'ACTIVITY',
    'NONE',
    'RULE',
    'EVENT_EXPRESS',
    'ACTIVITY_EXPRESS',
    'ACTIVITY_NOTIFICATION'
  ];
  static readonly LOG_TYPES = [
    // Processing dell'evento
    'EVENT_RECEIVED',
    'EVENT_PROCESSED',
    'EVENT_ERROR',
    'EVENT_EXPRESS_RECEIVED',
    'EVENT_EXPRESS_PROCESSED',
    'EVENT_EXPRESS_ERROR',
    // Processing dell'azione create dall'evento
    'RULE_START_PROCESSING',
    'RULE_END_PROCESSING',
    'RULE_ERROR',
    // Processing delle activity create
    'ACTIVITY_START_PROCESSING',
    'ACTIVITY_END_PROCESSING',
    'ACTIVITY_ERROR',
    // Activity provenienti da Event express
    'ACTIVITY_EXPRESS_START_PROCESSING',
    'ACTIVITY_EXPRESS_END_PROCESSING',
    'ACTIVITY_EXPRESS_ERROR',
    // Notifiche ricevute da Twilio
    'ACTIVITY_NOTIFICATION_READ',
    'ACTIVITY_NOTIFICATION_DELIVERED',
    'ACTIVITY_NOTIFICATION_SENT',
    'ACTIVITY_NOTIFICATION_FAILED',
    'ACTIVITY_NOTIFICATION_ACCEPTED',
    'ACTIVITY_NOTIFICATION_QUEUED',
    'ACTIVITY_NOTIFICATION_SENDING',
    'ACTIVITY_NOTIFICATION_UNDELIVERED'
  ];

}
