
const Home = {
  text: 'Dashboard',
  translate: 'menu.dashboard',
  link: '/home',
  icon: 'fa fa-tachometer-alt'
};

const Context = {
  text: 'Context',
  translate: 'menu.context',
  link: '/context',
  icon: 'fa fa-user-cog'
};

const Senders = {
  text: 'Senders',
  translate: 'menu.senders',
  link: '/senders',
  icon: 'fa fa-users-cog'
};

const Users = {
  text: 'User',
  translate: 'menu.users',
  link: '/user',
  icon: 'fa fa-user'
};

const Events = {
  text: 'Events',
  translate: 'menu.events',
  link: '/events',
  icon: 'fas fa-calendar-alt'
};

const Rules = {
  text: 'Rules',
  translate: 'menu.rules',
  link: '/rules',
  icon: 'fas fa-ruler-combined'
};

const Activities = {
  text: 'Activities',
  translate: 'menu.activities',
  link: '/activities',
  icon: 'fas fa-bookmark'
};

const Logs = {
  text: 'Logs',
  translate: 'menu.logs',
  link: '/logs',
  icon: 'fas fa-book'
};

const Schemas = {
  text: 'Schemas',
  translate: 'menu.schemas',
  link: '/schemas',
  icon: 'fas fa-code'
};

const Elements = {
    text: 'Elements',
    link: '/elements',
    icon: 'icon-chemistry',
    submenu: [
        {
            text: 'Buttons',
            link: '/elements/buttons'
        },
        {
            text: 'Colors',
            link: '/elements/colors'
        },
        {
            text: 'Interaction',
            link: '/elements/interaction'
        }
    ]
};

const headingMain = {
  text: 'Main Navigation',
  heading: true
};

export const menu = [
  Home,
  Context,
  Senders,
  Users,
  Events,
  Rules,
  Activities,
  Schemas,
  Logs,
];
