import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AppService } from "./base.service";
import { LoggingService } from "./log.service";
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

export interface ISender {
  id: number,
  senderCode: string,
  description: string,
  active: true,
  contextCode: string,
  expressEnabled: true,
  senderName: string
}

@Injectable({
  providedIn: 'root'
})
export class SenderService {

  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;

  private path = "/domain/find-senders";
  private pathList = "/domain/find-senders";
  private pathUpdate = "/domain/update-sender";
  private pathCreate = "/domain/create-sender";

  constructor( private appService: AppService, private logger: LoggingService ) { }

  getSenders(): Observable<any> {
    return this.appService.get( this.pathList ).pipe(
      map( response => {
        return response;
      })
    )
  }

  createSender( sender: ISender ): Observable<any> {
    return this.appService.post( this.pathCreate , sender ).pipe(
      map( response => {
        return response;
      } )
    )
  }

  updateSender( sender: ISender ): Observable<any> {
    return this.appService.put( this.pathUpdate , sender ).pipe(
      map( response => {
        return response;
      } )
    )
  }

}
