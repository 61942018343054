import { Component, Input , OnChanges, SimpleChanges} from "@angular/core";

import { UtilsService } from "../../../core/services/utils.service";

@Component({
	selector: 'app-sol-json-viewer-wrapper', 
	templateUrl: './sol-json-viewer-wrapper.component.html',
	styleUrls: ['./sol-json-viewer-wrapper.component.scss']
})
export class SolJsonViewerWrapperComponent implements OnChanges {

	@Input() data: any;
	@Input() expandedTree: boolean = false;

	public object: any;

	constructor(
		private utils: UtilsService
	) { 

		this.object = {};

	}

	ngOnChanges( changes: SimpleChanges ) {
		console.log( 'onChanges' )

		while( this.data && this.utils.isTypeObject(this.data) == false ) {
			console.log( 'while' , this.data )
			this.data = JSON.parse( this.data );
		}
		this.object['formatted'] = '<p>' + this.utils.toJson( this.data ) + '</p>';
		this.object['source'] = this.data;
		
	}

}