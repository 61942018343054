import { Component, OnInit, Input, Output } from '@angular/core';

import { ActivityLogsService, ILog } from '../../../core/services/activity-logs.service';

@Component({
  selector: 'app-s1-log-row',
  templateUrl: './s1-log-row.component.html',
  styleUrls: ['./s1-log-row.component.scss']
})
export class S1LogRowComponent implements OnInit {

  @Input() element: ILog;

  constructor( private log: ActivityLogsService) { }

  ngOnInit() {
  }

}
