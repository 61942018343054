import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {Observable} from "rxjs";
import 'rxjs/add/operator/do';
import {SettingsService} from "../settings/settings.service";
import {Router} from "@angular/router";
import { Injectable} from "@angular/core";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private settings: SettingsService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event
          && event.body.outcome
          && !event.body.outcome.success
          && event.body.outcome.code === "0005" ) {
          // Sessione scaduta
          this.settings.sessionExpired();
          this.router.navigate(["/login/0"]);
        }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          // redirect to the login route
          // or show a modal
        }
      }
    });
  }
}
